import { useQuery } from "@apollo/client";
import React, { useContext } from "react"

import reservationGet from "graphql/PMS/Reservation/query/reservationGet";
import { DateFormat } from "components/common/Format/DateFormat";
import { ContactDetails } from "../../Contact/Details/ContactDetails";
import { ReservationStatus } from "../Status/ReservationStatus";
import { ReservationRateList } from "../Rate/List/ReservationRateList";
import { ContactDisplay, ContactDisplayTypeEnum } from "../../Contact/Display/ContactDisplay";
import { ReservationPricePackageList } from "../PricePackage/List/ReservationPricePackageList";
import { Reservation, UserRoleEnum } from "model";
import { EventListForReservation } from "components/front/PLANNING/Event/List/EventListForReservation";
import { ReservationSyncButton } from "../Button/ReservationSyncButton";
import { userContext } from "context/User";
import { ReservationGroupDisplayButton } from "../Group/Display/ReservationGroupDisplayButton";


type ReservationFullDetailsProps = {
  reservationId: string,
}

export const ReservationFullDetails = (props: ReservationFullDetailsProps) => {
  const { reservationId } = props;


  const { data } = useQuery(reservationGet, {
    variables: {
      id: reservationId,
    },
    fetchPolicy: "cache-and-network",
    skip: !reservationId,
  })


  const reservation: Reservation = data?.reservation

  const defaultRate = reservation?.ReservationRate && reservation?.ReservationRate[0]
  const hasGuests = reservation?.ReservationGuest?.length > 0
  const hasOptions = reservation?.ReservationPricePackage?.length > 0
  const reservationGroupList = reservation?.ReservationGroup
  const hasGroup = reservationGroupList?.length > 0


  const user = useContext(userContext)
  const hasDebugRole = user.roles?.includes(UserRoleEnum.ROLE_DEBUG)


  return <div className="">
    <div className="card">
      <div className="card-header text-white" style={{ backgroundColor: `#${reservation?.Hotel?.themeColor}` }}>
        {reservation?.Hotel?.name}
      </div>
      <div className="card-body">
        <table className="table">
          <tbody>
            {hasDebugRole && <tr>
              <th>Identifiant</th>
              <td>{reservation?.id}</td>
            </tr>}
            <tr>
              <th>Résa</th>
              <td>
                #{reservation?.pmsId} - <ReservationStatus value={reservation?.status} /> - {reservation?.channel} -  {reservation?.totalIncTax} € TTC
              </td>
            </tr>
            <tr>
              <th>Chambre & Package</th>
              <td>{reservation?.ReservationRoom?.Room?.RoomType?.labelFr} - {defaultRate?.PriceRateType?.labelFr || ""}</td>
            </tr>
            <tr>
              <th>Nb Personnes </th>
              <td>Adultes: {reservation?.nbAdults} Enfants: {reservation?.nbChildren} Special: {reservation?.nbSpecial} </td>
            </tr>
            <tr>
              <th>Dates</th>
              <td><DateFormat value={new Date(reservation?.arrivalDate)} /> - <DateFormat value={new Date(reservation?.departureDate)} /> - {reservation?.nbNight} nuit(s)</td>
            </tr>
            {hasGuests && <tr>
              <th>Guest</th>
              <td>{reservation?.ReservationGuest?.map((rGuest: any) => {
                return <ContactDisplay type={ContactDisplayTypeEnum.ADMIN} contact={rGuest.Contact} />
              })}</td>
            </tr>}
            {hasOptions && <tr>
              <th>Options</th>
              <td><ReservationPricePackageList reservationPricePackageList={reservation?.ReservationPricePackage} /></td>
            </tr>}
            <tr>
              <th>Tarifs</th>
              <td><ReservationRateList reservationRateList={reservation?.ReservationRate} /></td>
            </tr>
            <tr>
              <th>Contact</th>
              <td><ContactDetails contact={reservation?.Contact} /></td>
            </tr>
            <tr>
              <th>Services</th>
              <td>
                <EventListForReservation reservationId={reservation?.id} hotelId={reservation?.hotelId} />
              </td>
            </tr>
            {hasGroup && <tr>
              <th>Groupe(s)</th>
              <td>
                <div className="alert alert-danger">Cette réservation fait partie d'un ou de plusieurs groupes</div>
                {reservationGroupList?.map(reservationGroup => {
                  return <ReservationGroupDisplayButton key={`reservation_group_${reservationGroup?.id}`} reservationGroup={reservationGroup} onDone={() => { }} />
                })}
              </td>
            </tr>}
            <tr>
              <th>Actions</th>
              <td>
                <ReservationSyncButton reservationId={reservation?.id} />
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
} 
