import React, { useContext, useEffect, useRef, useState } from "react"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Dropdown from 'react-bootstrap/Dropdown'

import { DateInput } from "components/common/Form/DateInput"
import { formatErrorResponseForJoi, isFieldOnError } from "helpers/Apollo"

import './style.scss'

import { HotelListSelect } from "../../Hotel/List/HotelListSelect"
import { RoomTypeView } from "../../Room/Type/View/RoomTypeView"
import { PriceRateDisplay } from "../../PriceRate/Display/PriceRateDisplay"
import { Proposition, PropositionInput, PropositionPushPmsEnum, PropositionRowRoomNightInput, PropositionSentStatusEnum, PropositionStatusEnum, RoomAvailibility, RoomAvailibilityItem, UserRoleEnum } from "model"
import { PropositionRowInput } from "model"
import { PropositionRowSelect } from "../Row/Select/PropositionRowSelect"
import { PropositionSelectButton } from "../Button/PropositionSelectButton"
import { PropositionRowForm } from "../Row/Form/PropositionRowForm"
import { Collapse } from "components/common/Navigation/Collapse/Collapse"
import { ContactSearchField } from "../../Contact/Field/ContactSearchField"
import { DateTimeInput } from "components/common/Form/DateTimeInput"
import { TextInput } from "components/common/Form/TextInput"

import propositionGetGql from "graphql/PMS/Proposition/query/propositionGet.gql"
import propositionValid from "graphql/PMS/Proposition/mutation/propositionValid"
import propositionSendGql from "graphql/PMS/Proposition/mutation/propositionSend.gql"
import propositionGetAvailibilitiesQuery from "graphql/PMS/Proposition/query/propositionGetAvailibilities"
import { Route, useHistory } from "react-router-dom"
import { URL_PROPOSITION_EDIT, URL_PROPOSITION_LIST, URL_PROPOSITION_PUSH } from "constant/url"
import { Modal } from "components/common/Navigation/Modal/Modal"
import { PropositionPush } from "../Push/PropositionPush"
import { Loop, getRootUrl } from "helpers/Utils"
import { findToken } from "helpers/User"
import { userContext } from "context/User"


type PropositionFormProps = {
  warnings?: any,
  errors: any,
  input: PropositionInput,
  onChange: (proposition: PropositionInput) => void,
  onSave: () => Promise<void>,
}


const ROW_WIDTH = 80


export const PropositionForm = (props: PropositionFormProps) => {

  const input = props?.input
  const hasInputRows = input?.propositionRow?.length > 0
  const history = useHistory()

  const user = useContext(userContext)

  const [focus, updateFocusState] = useState<"AVAILIBILITY" | "PARAMETERS" | "ROW">("AVAILIBILITY")
  const [arrivalDate, updateArrivalDate] = useState<number>(null)
  const [departureDate, updateDepartureDate] = useState<number>(null)
  const [availError, updateAvailError] = useState<any>(null)
  const [isSaved, updateIsSaved] = useState<boolean>(null)
  const [displayPreview, updateDisplayPreview] = useState<boolean>(null)
  const [isBasketSticked, updateIsBasketSticked] = useState<boolean>(false)
  const [loadingSave, updateLoadingSave] = useState<boolean>(null)
  const [loadingConfirm, updateLoadingConfirm] = useState<boolean>(null)
  const [sendPropositionMutate, { loading: loadingSend }] = useMutation(propositionSendGql);
  const [validPropositionMutate, { loading: loadingValid }] = useMutation(propositionValid);


  const [availLoading, updateAvailLoading] = useState(false)
  const [row, updateRow] = useState<PropositionRowInput>()

  const elementRef = useRef()

  const updateFocus = (state: "AVAILIBILITY" | "PARAMETERS" | "ROW") => {
    window.scrollTo(0, 0)
    updateFocusState(state)
  }


  useEffect(() => {
    if (focus === "AVAILIBILITY" && input?.propositionRow?.length > 0) {
      updateFocus("ROW")
    }
  }, [input?.id])

  useEffect(() => {

    const arrivalDateStamp = new Date(arrivalDate)?.getTime()
    const nbDays = (departureDate && arrivalDate) ? Number(new Date(departureDate)?.getTime() - new Date(arrivalDate)?.getTime()) / Number(1000 * 3600 * 24) : 0
    const rowCreated = populateRow(nbDays, arrivalDateStamp)
    updateRow(rowCreated)

  }, [input?.nbRooms])



  //Fetching Proposition to get status
  const { data } = useQuery<{ result: { proposition: Proposition, warning: any } }>(propositionGetGql, {
    variables: {
      id: input?.id,
    },
    skip: !input?.id,
  })

  const { refetch } = useQuery(propositionGetAvailibilitiesQuery, {
    skip: true,
    notifyOnNetworkStatusChange: true,
  })

  const [availibilityDetails, updateAvailibilityDetails] = useState<RoomAvailibility[]>(null)


  const onSave = async () => {
    updateIsSaved(false)
    updateLoadingSave(false)
    return props?.onSave()
      .then(() => {
        updateIsSaved(true)
        updateLoadingSave(false)
      })
      .catch(() => {
        updateIsSaved(false)
        updateLoadingSave(false)
      })
  }

  const onConfirm = async () => {
    updateLoadingConfirm(false)
    return validPropositionMutate({
      variables: {
        id: proposition?.id,
        input,
      }
    })
      .then(() => updateLoadingConfirm(false))
      .catch(() => updateLoadingConfirm(false))
  }

  const onSend = async () => {
    sendPropositionMutate({
      variables: {
        id: proposition?.id,
      }
    })
      .then(() => {
        updateDisplayPreview(false)
        history.push(URL_PROPOSITION_LIST)
      })
      .catch((e: any) => {
        // setError(`Ooops... ${e.message}`)
        console.log('ERROR', e)
      })
  }


  const onChange = (value: PropositionInput) => {
    updateIsSaved(false)
    props.onChange(value)
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    onSearch()
  }

  const addRowItem = (roomIndex: number, dateStamp: number, roomId: string) => {

    const rowToUpdate = row;

    const rowRoomItem: PropositionRowRoomNightInput = {
      roomId,
      dateStamp,
    }

    if (!rowToUpdate.roomList[roomIndex]) {
      rowToUpdate.roomList[roomIndex] = {
        roomIndex,
        itemList: []
      }
    }

    const hasRoomNight = !!rowToUpdate.roomList[roomIndex]?.itemList.find((el) => el.dateStamp === dateStamp)

    if (hasRoomNight) {
      //Update Room Night
      rowToUpdate.roomList[roomIndex].itemList = rowToUpdate.roomList[roomIndex].itemList.map((el) => (el.dateStamp === dateStamp) ? rowRoomItem : el)
    } else {
      //Add Room Night,
      rowToUpdate.roomList[roomIndex].itemList = [...rowToUpdate.roomList[roomIndex]?.itemList, rowRoomItem]
    }

    updateRow({
      ...rowToUpdate,
    })

  }

  const addToBasket = (row: PropositionRowInput) => {

    //Adding row to prospsition
    onChange({
      ...input,
      propositionRow: [
        ...input.propositionRow,
        row
      ]
    })

    //Creating new selection row just ine case
    const arrivalDateStamp = new Date(arrivalDate)?.getTime()
    const nbDays = (departureDate && arrivalDate) ? Number(new Date(departureDate)?.getTime() - new Date(arrivalDate)?.getTime()) / Number(1000 * 3600 * 24) : 0
    const rowCreated = populateRow(nbDays, arrivalDateStamp)
    updateRow(rowCreated)

  }

  const populateRow = (nbDays: number, arrivalDateStamp: number) => {
    const rowCreated: PropositionRowInput = {
      departureDate: new Date(departureDate),
      arrivalDate: new Date(arrivalDate),
      roomList: [],
    }

    for (let i = 0; i < input?.nbRooms; i++) {
      const itemList: PropositionRowRoomNightInput[] = []
      for (let j = 0; j < nbDays; j++) {
        const dateStamp = arrivalDateStamp + (j * 1000 * 3600 * 24)

        itemList.push({
          roomId: null,
          dateStamp,
        })
      }

      rowCreated.roomList.push({
        roomIndex: i,
        itemList,
      })
    }
    return rowCreated
  }


  const onSearch = async () => {
    try {
      updateAvailLoading(true)
      const result = await refetch({
        input: {
          arrivalDate: arrivalDate && new Date(arrivalDate),
          departureDate: departureDate && new Date(departureDate),
          hotelId: input?.hotelId,
          nbAdults: input?.nbAdults,
          nbChildren: input?.nbChildren,
          nbSpecial: input?.nbSpecial,
        }
      })

      const arrivalDateStamp = new Date(arrivalDate)?.getTime()
      const nbDays = (departureDate && arrivalDate) ? Number(new Date(departureDate)?.getTime() - new Date(arrivalDate)?.getTime()) / Number(1000 * 3600 * 24) : 0
      const rowCreated = populateRow(nbDays, arrivalDateStamp)

      updateRow(rowCreated)
      updateAvailibilityDetails(result?.data?.availibility)
      updateAvailError(null)
      // updateError([])

    } catch (e: any) {
      updateAvailError(formatErrorResponseForJoi(e))
    }
    updateAvailLoading(false);
  }

  const hasAvailibilityResults = !!availibilityDetails
  const listDateResult = hasAvailibilityResults && availibilityDetails[0]?.availibilityList?.map((el: any) => el.date)
  const roomTypeIdList = hasAvailibilityResults && Object.keys(availibilityDetails.reduce((acc: any, el: any) => {
    //Filtering
    return el?.room?.RoomType?.canBeSelectedInProposition ? {
      ...acc,
      [el.room.roomTypeId]: true
    } : acc
  }, {}))

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y === 0) return
      if (!isBasketSticked && currPos.y < 10) {
        updateIsBasketSticked(true)
      }
      if (isBasketSticked && currPos.y > 10) {
        updateIsBasketSticked(false)
      }
    }, [isBasketSticked], elementRef)


  const warning = props?.warnings
  const error = props?.errors
  const hasErrors = error && Object.keys(error).length > 0
  const hasWarnings = warning && Object.keys(warning).length > 0

  const hasParametersWarning: boolean = warning && (warning.contactId || warning.expirationDate || warning.pushPms)
  const hasRowWarning: boolean = (warning && warning?.propositionRow) && (Object.keys(warning?.propositionRow)?.length > 0)

  const isAdmin = user?.roles.includes(UserRoleEnum.ROLE_ADMIN)
  const userHasPushRights = isAdmin || user?.roles?.includes(UserRoleEnum.ROLE_PMS_PROPOSITION_PUSH)


  const proposition = data?.result?.proposition
  // const canSave = !loadingSave && (proposition?.status === undefined ||
  //   proposition?.status === PropositionStatusEnum.STATUS_DRAFT ||
  //   proposition?.status === PropositionStatusEnum.STATUS_VALID ||
  //   proposition?.status === PropositionStatusEnum.STATUS_SENT ||
  //   proposition?.status === PropositionStatusEnum.STATUS_READ ||
  //   proposition?.status === PropositionStatusEnum.STATUS_ERROR
  // )
  const canSave = !loadingSave

  const canConfirm = !hasErrors && proposition?.status === PropositionStatusEnum.STATUS_DRAFT && proposition?.contactId && proposition?.expirationDate
  const isValid = proposition?.status === PropositionStatusEnum.STATUS_VALID
  const canSend = isValid && proposition?.status !== PropositionStatusEnum.STATUS_SENT
  const canSelectPush = userHasPushRights
  const canPush = userHasPushRights && (proposition?.status === PropositionStatusEnum.STATUS_CONFIRMED && (proposition?.pushPms === PropositionPushPmsEnum.PROPOSITION_PUSH_POST_WAITLIST || proposition?.pushPms === PropositionPushPmsEnum.PROPOSITION_PUSH_POST_CONFIRMED || proposition?.pushPms === PropositionPushPmsEnum.PROPOSITION_PUSH_PRE_PROVISONAL))
  const isSent = proposition?.sentStatus === PropositionSentStatusEnum.STATUS_SENT

  const hasAvailGlobalErrors = !!availError?.global

  return <div className="proposition-container">
    <Route path={URL_PROPOSITION_PUSH} >
      <Modal display={true} onClose={() => history.push(URL_PROPOSITION_EDIT.replace(':id', proposition?.id))}>
        <PropositionPush propositionId={proposition?.id} />
      </Modal>
    </Route>
    <Modal size={"xl"} display={displayPreview} onClose={() => updateDisplayPreview(false)}>
      <div className="card">
        <div className="card-body">
          <iframe style={{ height: '80vh' }} width='100%' title="proposition" src={`${getRootUrl()}proposition/${proposition?.id}?access_token=${findToken()}`} />
        </div>
        <div className="card-footer">
          <button className="btn btn-success" disabled={!canSend} onClick={onSend} style={{ marginRight: 5 }}>{loadingSend && <span className="icon-loading"></span>}Envoyer</button>
        </div>
      </div>
    </Modal>
    <div className="card">
      <div className="card-header" onClick={() => updateFocus((focus === "AVAILIBILITY" ? null : 'AVAILIBILITY'))} style={{ height: 60, fontSize: "2em", display: "flex", justifyContent: "left", alignItems: "center" }}>1. Disponibilités</div>
      <Collapse collapsed={focus !== "AVAILIBILITY"}>
        <div className="card-body">

          {hasAvailGlobalErrors && <div className="alert alert-danger">{availError?.global}</div>}

          <form onSubmit={onSubmit}>
            <div style={{ position: "sticky", top: 150 }}>
              <div className="card">
                <div className="card-header bg-dark text-white">
                  Paramètres
                </div>
                <div className="card-body">
                  <DateInput isError={isFieldOnError(availError, "arrivalDate")} label="Date d'arrivée" value={arrivalDate} onChange={(value: any) => {
                    const dayAfter = value + (1000 * 3600 * 24)
                    updateArrivalDate(value)
                    updateDepartureDate(dayAfter)
                  }} />
                  <DateInput isError={isFieldOnError(availError, "departureDate")} label="Date de départ" value={departureDate} onChange={(value: any) => {
                    updateDepartureDate(value)

                  }} />
                  <div className="input-group">
                    <span className="input-group-text">Nombre de Chambres</span>
                    <select className="form-control" value={input?.nbRooms || 1} onChange={(e: any) => {
                      onChange({
                        ...input,
                        nbRooms: Number(e.target.value),
                      })
                    }}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                    {isFieldOnError(availError, "nbRooms") && <span className="input-group-text"><span className="icon-warning-sign" /></span>}
                  </div>
                  <HotelListSelect hotelId={input?.hotelId} isError={isFieldOnError(availError, "hotelId")} label='Hotel' onChange={(hotelId: string) => {
                    updateAvailibilityDetails(null)
                    onChange({
                      ...input,
                      hotelId,
                    })
                  }} />
                  <div className="">
                    <div className="alert alert-info">Les champs "Nombres de Personnes" sont maintenant en bas au niveau de 3. Informations </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-dark" type="submit" disabled={availLoading}>{availLoading && <span className="icon-loading"></span>} Rechercher</button>
                </div>
              </div>
            </div>
          </form>
          {hasAvailibilityResults && <>
            <div className="results-container">

              <div className="card">
                <div className="card-body">
                  <div ref={elementRef} />
                  <div className="basket-anchor" style={{ position: (isBasketSticked && focus === "AVAILIBILITY") ? "fixed" : "inherit", top: 10, zIndex: 1000 }}>
                    <PropositionRowSelect
                      rowWidth={ROW_WIDTH}
                      row={row}
                      nbRooms={input?.nbRooms}
                      onAdd={row => {
                        addToBasket(row)
                      }}
                    />
                  </div>

                  {roomTypeIdList.map((roomTypeId: any, index: any) => {

                    const availList = [...(availibilityDetails || [])].filter((el) => el.room.roomTypeId === roomTypeId)
                      ?.sort((el1, el2) => el1.room.number > el2.room?.number ? 1 : -1)

                    return <div className="input-group" key={`categoruy_${roomTypeId}`}>
                      <span className='input-group-text category-label'>
                        <RoomTypeView id={roomTypeId} isFrench={true} />
                      </span>
                      <div className="form-control category-group">
                        <div className="row d-flex" style={{ flexWrap: "nowrap" }} >
                          <div style={{ width: ROW_WIDTH }} className="d-flex justifiy-content-center"></div>
                          {listDateResult?.map((date: any, indexChild: number) => {
                            return <div className="d-flex justify-content-center" style={{ width: ROW_WIDTH }}>
                              <PriceRateDisplay hotelId={input?.hotelId} roomTypeId={roomTypeId} nbSpecial={input?.nbSpecial} nbChildren={input?.nbChildren} nbAdults={input?.nbAdults} date={new Date(date)} />
                            </div>
                          })}
                        </div>
                        {availList.map((avail, index: any) => {
                          const roomId = avail?.room?.id
                          const roomNumber = avail?.room?.number
                          const availList = avail?.availibilityList


                          const onRowSelect = (index: number) => {
                            availList?.map(avail => {
                              const date = new Date(avail?.date)
                              // const dateStamp = date.getTime() + (2 * 1000 * 3600) //OFFSET
                              const dateStamp = date.getTime()

                              const isAvailable = avail?.isAvailable
                              const roomCanBeSelected = !row.roomList?.reduce((acc, rowRoom) => {
                                return !acc ? rowRoom?.itemList?.reduce((acc, night) => {
                                  // @ts-ignore
                                  return !acc ? (night?.roomId === roomId && night.dateStamp == dateStamp) : true
                                }, false) : true
                              }, false)

                              if (isAvailable && roomCanBeSelected) {
                                addRowItem(index, dateStamp, roomId)
                              }
                              return avail
                            })
                          }

                          const isSingleChoice = input?.nbRooms > 1

                          return <div className="row" key={`index_avail_${index}`} style={{ flexWrap: "nowrap" }}>
                            <div className="choice" style={{ width: ROW_WIDTH }}>

                              {isSingleChoice && <Dropdown>
                                <Dropdown.Toggle size="sm" variant="info" id="dropdown-basic" >
                                  {roomNumber}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {Loop(input?.nbRooms).map((index: number) => {
                                    const roomNumber = index + 1
                                    return <Dropdown.Item onSelect={() => {
                                      onRowSelect(index)
                                    }} key={`select_${index}`} value={`${index}`}>Ch {roomNumber}</Dropdown.Item>
                                  })}
                                </Dropdown.Menu>
                              </Dropdown>}
                              {!isSingleChoice && <button className="btn btn-sm btn-info" onClick={() => onRowSelect(0)}>{roomNumber}</button>}
                            </div>
                            {availList?.map((availValue: RoomAvailibilityItem, indexChild: number) => {
                              const isAvailable = availValue?.isAvailable
                              const date = new Date(availValue?.date)
                              // const dateStamp = date.getTime() + (2 * 1000 * 3600) //OFFSET
                              const dateStamp = date.getTime()

                              const roomCanBeSelected = !row.roomList?.reduce((acc, rowRoom) => {
                                return !acc ? rowRoom?.itemList?.reduce((acc, night) => {
                                  return !acc ? (night?.roomId === roomId && night.dateStamp == dateStamp) : true
                                }, false) : true
                              }, false)

                              const roomIndex = row?.roomList?.findIndex((rowRoom) => {
                                return rowRoom?.itemList?.reduce((acc, night) => {
                                  return !acc ? (night?.roomId === roomId && night.dateStamp == dateStamp) : true
                                }, false)
                              })

                              const isDisabled = !roomCanBeSelected
                              const selectValue: string = roomIndex > -1 ? `${roomIndex}` : null

                              return <div className={(isAvailable) ? "border border-dark bg-success choice" : "border border-dark bg-danger choice"} key={`index_avail_${index}_${indexChild}`} style={{ width: ROW_WIDTH }}>
                                {isAvailable && <div className="d-flex mr-1 justify-content-center">
                                  <PropositionSelectButton isDisabled={isDisabled} valueIndex={selectValue} nbRooms={input?.nbRooms} onAdd={(index: number) => {
                                    addRowItem(index, dateStamp, roomId)
                                  }} />
                                </div>}

                              </div>
                            })}
                          </div>
                        })}
                      </div>
                    </div>
                  })}

                </div>
              </div>
            </div>
          </>
          }
        </div>
      </Collapse>
    </div>

    <div className="card" style={{ position: (focus !== "ROW" && hasInputRows) ? "sticky" : "inherit", bottom: 0 }}>
      <div className="card-header" onClick={() => updateFocus(focus === "ROW" ? null : "ROW")} style={{ height: 60, fontSize: "2em", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span>
          2. Séjours
          {hasRowWarning && <span className="icon-warning ml-3" />}
        </span>
        {hasInputRows && <span className="badge badge-primary mr-3">{input?.propositionRow?.length}</span>}
      </div>
      <Collapse collapsed={focus !== "ROW"}>
        <div className="card-body">

          {input?.propositionRow?.map((row, rowIndex) => {

            const isSelected = proposition?.rowSelectedId && proposition?.rowSelectedId === row?.id

            return <div className="card mb-3" key={`row_${row?.id}_${rowIndex}`}>
              <div className="card-header bg-primary text-white">Proposition {rowIndex + 1}</div>
              <div className="card-body">
                <PropositionRowForm
                  isSelected={isSelected}
                  errors={error?.propositionRow && error?.propositionRow[rowIndex]}
                  warnings={warning?.propositionRow && warning?.propositionRow[rowIndex]}
                  hotelId={input?.hotelId}
                  nbAdults={input?.nbAdults}
                  nbChildren={input?.nbChildren}
                  nbSpecial={input?.nbSpecial}
                  row={row}
                  onChange={(updatedRow) => {
                    onChange({
                      ...input,
                      propositionRow: input?.propositionRow?.map((oldRow, idx) => {
                        return (idx === rowIndex) ? updatedRow : oldRow
                      })
                    })
                  }} />
              </div>
              <div className="card-footer">
                <button className="btn btn-sm btn-danger" onClick={() => {
                  onChange({
                    ...input,
                    propositionRow: input?.propositionRow?.filter((oldRow, idx) => {
                      return idx !== rowIndex
                    })
                  })

                }}>
                  <span className="icon-trash mr-1" />
                  Supprimer ce séjour
                </button>
              </div>
            </div>
          })}
        </div>
      </Collapse>
    </div>


    <div className="card">
      <div className="card-header" onClick={() => updateFocus((focus === "PARAMETERS") ? null : 'PARAMETERS')} style={{ height: 60, fontSize: "2em", display: "flex", justifyContent: "left", alignItems: "center" }}>
        3. Informations
        {hasParametersWarning && <span className="icon-warning ml-3" />}
      </div>
      <Collapse collapsed={focus !== "PARAMETERS"}>

        <ContactSearchField isWarning={isFieldOnError(warning, "contactId")} isError={isFieldOnError(error, "contactId")} value={input?.contactId} label="Contact" hotelId={input?.hotelId} onChange={(contactId: any) =>
          onChange({
            ...input,
            contactId,
          })}
        />
        <div className="input-group">
          <span className="input-group-text">Nombre de personnes</span>
          <div className="form-control" style={{ height: "100%" }}>
            <div className="input-group">
              <span className="input-group-text">Adultes</span>
              <input className="form-control" value={input?.nbAdults} type="number" min="0" step="1" onChange={(e: any) => {
                onChange({
                  ...input,
                  nbAdults: Number(e.target.value),
                })
              }} />
            </div>
            <div className="input-group">
              <span className="input-group-text">Enfants</span>
              <input className="form-control" value={input?.nbChildren} type="number" min="0" step="1" onChange={(e: any) => {
                onChange({
                  ...input,
                  nbChildren: Number(e.target.value),
                })
              }} />
            </div>
            <div className="input-group">
              <span className="input-group-text">Special</span>
              <input className="form-control" value={input?.nbSpecial} type="number" min="0" step="1" onChange={(e: any) => {
                onChange({
                  ...input,
                  nbSpecial: Number(e.target.value),
                })
              }} />
            </div>
          </div>
        </div>
        <DateTimeInput isWarning={isFieldOnError(warning, "expirationDate")} isError={isFieldOnError(error, "expirationDate")} label="Date d'Expiration" value={input?.expirationDate || ""} onChange={(value: string) => {
          onChange({
            ...input,
            expirationDate: value,
          })
        }} />
        <div className="input-group">
          <span className="input-group-text">Pusher dans le PMS ?</span>
          <select value={input?.pushPms || ""} className='form-control' onChange={(e: any) => {
            onChange({
              ...input,
              pushPms: e.target.value,
            })
          }}>
            <option value="">Choisissez une valeur</option>
            <option value={PropositionPushPmsEnum.PROPOSITION_PUSH_NONE}>Aucun push</option>
            <option disabled={!canSelectPush} value={PropositionPushPmsEnum.PROPOSITION_PUSH_PRE_PROVISONAL}>Bloquer les chambres maintenant</option>
            <option disabled={!canSelectPush} value={PropositionPushPmsEnum.PROPOSITION_PUSH_POST_WAITLIST}>Bloquer en Liste d'Attente dès confirmation du client</option>
            <option disabled={!canSelectPush} value={PropositionPushPmsEnum.PROPOSITION_PUSH_POST_CONFIRMED}>Bloqué en Confirmé dès confirmation du client</option>
          </select>
          {error?.pushPms && <span className="input-group-text"><span className="icon-warning-sign"> </span></span>}
          {warning?.pushPms && <span className="input-group-text"><span className="icon-warning"> </span></span>}
        </div>
        <TextInput label={"Commentaire global"} value={input?.comment} id="comment" onChange={(_, v) => {
          onChange({
            ...input,
            comment: v,
          })
        }} rightLabel={<span className="badge badge-warning">Visible par le client</span>} />


      </Collapse>
    </div>

    <div className="card mt-2" >


      <div className="card-header">
        Actions
      </div>
      <div className="card-body">
        {error?.hotelId && <div className="alert alert-danger">Hotel non sélectionnée</div>}
        {isSent && <div className="alert alert-warning">Proposition Envoyée</div>}
        {hasWarnings && <div className="alert alert-warning">Proposition Incomplète</div>}
        {isSaved && <div className="alert alert-primary">Modifications Enregistées</div>}
        {canConfirm && <div className="alert alert-info">Finaliser cette proposition pour pouvoir l'envoyer</div>}
        {(isValid && !isSent) && <div className="alert alert-warning">Proposition en attente d'envoi</div>}
      </div>
      <div className="card-footer">

        <button className="btn btn-sm btn-dark" onClick={onSave} disabled={!canSave} style={{ marginRight: 5 }}>{loadingSave && <span className="icon-loading"></span>}Enregistrer</button>
        <button className="btn btn-sm btn-primary" onClick={onConfirm} disabled={!canConfirm} style={{ marginRight: 5 }}>{loadingValid && <span className="icon-loading"></span>}Finaliser</button>
        <button className="btn btn-sm btn-success" disabled={!canSend} onClick={() => updateDisplayPreview(true)} style={{ marginRight: 5 }}>Envoyer</button>
        <button className="btn btn-sm btn-danger" disabled={!canPush} onClick={() => history.push(URL_PROPOSITION_PUSH?.replace(":id", proposition?.id))} style={{ marginRight: 5 }}>Pusher</button>

      </div>
    </div>

  </div >
}