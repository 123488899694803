import gql from "graphql-tag";
import PostStayGroupFragment from "../../Group/fragment/PostStayGroupFragment";

export default gql`

${PostStayGroupFragment}

fragment PostStayConfigFragment on PostStayConfig {
  id
  hotelId
  dest
  sendHour
  channel
  postStayActive
  demoMode
  templateId
  alertThreshold
  nbDaysAfterDeparture
  PostStayGroup {
    ...PostStayGroupFragment
  }
}

`