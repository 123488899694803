import { ReservationGroup } from 'model'
import React, { useState } from 'react'
import { ReservationButton } from '../../Button/ReservationButton'
import { ReservationGroupEdit } from '../Edit/ReservationGroupEdit'
import { Modal } from 'components/common/Navigation/Modal/Modal'

type ReservationGroupDisplayButtonProps = {
  reservationGroup: ReservationGroup,
  onDone: () => void,
}

export const ReservationGroupDisplayButton = (props: ReservationGroupDisplayButtonProps) => {

  const reservationGroup = props?.reservationGroup
  const reservationIdList = reservationGroup.ReservationGroupLink?.map((rgl) => rgl.reservationId)
  const [focusGroupEdit, updateFocusGroupEdit] = useState<any>()

  const onDone = () => {
    updateFocusGroupEdit(false)
    props?.onDone && props?.onDone()
  }

  const limit = 4
  const nbResas = reservationIdList?.length
  const limitReached = nbResas > limit

  return <>
    <Modal display={!!focusGroupEdit} onClose={() => onDone()}>
      <ReservationGroupEdit id={focusGroupEdit} onDone={() => onDone()} />
    </Modal>
    <span className="ml-1 mb-1">
      <button className='btn btn-warning btn-sm' onClick={() => updateFocusGroupEdit(reservationGroup?.id)}>Groupe</button>
    </span>
    {!limitReached && reservationIdList?.map((reservationId) => <span className="ml-1 mb-1" key={`resa_button_${reservationId}`} >
      <ReservationButton reservationId={reservationId} />
    </span>)}
    {limitReached && <span className="ml-1 mb-1">
      <button className='btn btn-sm btn-warning ' onClick={() => updateFocusGroupEdit(reservationGroup?.id)}>Voir les {nbResas} résas...</button>
    </span>}

  </>

}