import { useQuery } from "@apollo/react-hooks"
import { IconWarningSign } from "@zipou/front_tools"
import { StarDisplay } from "components/common/Form/StarDisplay"
import { ContactDetails } from "components/front/PMS/Contact/Details/ContactDetails"
import postStayGetGql from "graphql/PMS/Stay/Post/query/postStayGet.gql"
import { PostStay, PostStayGroupItem, PostStayGroupItemTypeEnum, PostStayGroupSeparator, PostStayQuestion, PostStayQuestionTypeEnum } from "model"
import React from "react"

type PostStayDisplayProps = {
  postStayId: string,
}

export const PostStayDisplay = ({ postStayId }: PostStayDisplayProps) => {

  const { data } = useQuery<{ postStay: PostStay }>(postStayGetGql, {
    variables: {
      id: postStayId,
    }
  })

  const postStay = data?.postStay
  const postStayConfig = postStay?.PostStayConfig
  const postStayGroup = postStayConfig?.PostStayGroup


  const alertThresold = postStayConfig?.alertThreshold

  const renderQuestion = (question: PostStayQuestion) => {

    const answerValue = postStay?.AnswerList?.find((el: any) => el?.questionId === question?.id)?.value || null

    const isTypeStar = question?.type === PostStayQuestionTypeEnum.TYPE_STAR
    const isTypeText = question?.type === PostStayQuestionTypeEnum.TYPE_TEXT
    const isTypeTextarea = question?.type === PostStayQuestionTypeEnum.TYPE_TEXTAREA
    const isTypeChoice = question?.type === PostStayQuestionTypeEnum.TYPE_CHOICE
    const isTypeChoiceRadio = question?.type === PostStayQuestionTypeEnum.TYPE_CHOICE_RADIO
    const isTypeBoolean = question?.type === PostStayQuestionTypeEnum.TYPE_BOOLEAN

    const hasCondition = !!question?.conditionKey

    const hasAlertValue = !!question?.alertValue
    const alertTriggered = (hasAlertValue && question?.alertValue === answerValue) || (Number(answerValue) <= alertThresold)

    const findKey = hasCondition && postStayGroup?.reduce<PostStayGroupItem>(((acc, group, index) => {
      const item = group?.PostStayGroupItem?.find(item => {
        return item.Question?.name === question?.conditionKey
      })
      return item ? item : acc
    }), null)


    const condtionResponse = postStay?.AnswerList?.find((el: any) => el?.questionId === findKey?.Question?.id)?.value || null
    const questionMustBeDisplayed = hasCondition ? (!!condtionResponse && condtionResponse == question?.conditionValue) : !!answerValue

    return questionMustBeDisplayed ? <>
      <td>
        {alertTriggered &&
          <span className="mr-2">
            <IconWarningSign />
          </span>}
        {question?.labelFr}</td>
      <td style={{ width: '30%' }}>
        {isTypeStar && <>
          <StarDisplay fontSize={24} value={answerValue} />
        </>}
        {(isTypeText || isTypeTextarea || isTypeChoice || isTypeChoiceRadio || isTypeBoolean) && <span>
          {answerValue}
        </span>}
        {/* {question?.type} */}
      </td>
    </> : <></>
  }

  const renderSeparator = (separator: PostStayGroupSeparator) => {
    return <>
      <td className="bg-secondary text-white" colSpan={2}>{separator?.labelFr}</td>
    </>
  }

  const hasAnswer = postStay?.AnswerList?.length > 0

  return <div>
    {/* <ReservationFullDetails reservationId={postStay?.Reservation?.id} /> */}
    <div className="card">
      <div className="card-header bg-dark text-white">
        Contact
      </div>
      <div className="card card-body">
        <ContactDetails contact={postStay?.Contact} />
      </div>
    </div>
    {!hasAnswer && <div className="alert alert-info">
      Pas de réponses enregistrés
    </div>}
    {postStayConfig?.PostStayGroup?.map(postStayGroup => {
      return <div key={`postStayGroup_${postStayGroup?.id}`}>
        <table className="table table-striped table-bordered">
          <thead>
            <tr className="bg-info text-white">
              <th colSpan={2} className="bg-info text-white">{postStayGroup?.labelFr}</th>
            </tr>
          </thead>
          <tbody>
            {postStayGroup?.PostStayGroupItem?.map(groupItem => {
              const isQuestion = groupItem?.type === PostStayGroupItemTypeEnum.TYPE_QUESTION

              return <tr key={`groupItem_${groupItem?.id}`}>
                {isQuestion && renderQuestion(groupItem?.Question)}
                {!isQuestion && renderSeparator(groupItem.Separator)}
              </tr>
            })}
          </tbody>
        </table>
      </div>
    })}
  </div>

}