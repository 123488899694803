import { useQuery } from "@apollo/react-hooks"
import hotelUpdate from "graphql/PMS/Hotel/mutation/hotelUpdate"
import hotelGet from "graphql/PMS/Hotel/query/hotelGet"
import { formatErrorResponseForJoi } from "helpers/Apollo"
import React, { useContext, useState } from "react"

import priceRateCancelConditionListGql from "graphql/PMS/PriceRate/CancelCondition/query/priceRateCancelConditionList.gql"
import { Hotel, HotelInput, PostStayGroup, UserRoleEnum } from "model"
import { HotelForm } from "../Form/HotelForm"
import { userContext } from "context/User"
import { MutationWrapper } from "components/MutationWrapper"
import { hotelContext } from "context/hotelContext"

type HotelEditProps = {
  id: string,
  open: boolean,
  focus?: string,
}

export const HotelEdit = (props: HotelEditProps) => {

  const [hotel, updateHotel] = useState<HotelInput>(null)
  const [errors, updateErrors] = useState(null)
  const [formUpdated, updateFormUpdated] = useState<boolean>(false)

  const user = useContext(userContext)

  const { data } = useQuery<{ hotel: Hotel }>(hotelGet, {
    variables: {
      id: props?.id,
    },
    skip: !props?.id || !user,

    onCompleted: (data) => {
      updateHotel(hotelGetInput(data?.hotel))
    },
    // fetchPolicy: "cache-and-network",
  })

  const hotelGetInput = (hotel: Hotel): HotelInput => {

    return {
      configGeneral: {
        name: hotel?.name,
        email: hotel?.email,
        emailSender: hotel?.emailSender,
        address: hotel?.address,
        phoneNumber: hotel?.phoneNumber,
        stayTax: hotel.stayTax,
        checkInHour: hotel.checkInHour,
        checkOutHour: hotel.checkOutHour,
        notificationDest: hotel?.notificationDest,
        // demoMode: hotel?.demoMode,
        breakfastStartHour: hotel?.breakfastStartHour,
        breakfastEndHour: hotel?.breakfastEndHour,
        cgvUrlEnFileId: hotel?.cgvUrlEnFileId,
        cgvUrlFrFileId: hotel?.cgvUrlFrFileId,
        initials: hotel?.initials,
        logoUrl: hotel?.logoUrl,
        themeColor: hotel?.themeColor,
        backgroundUrl: hotel?.backgroundUrl,
        backgroundThemeColor: hotel?.backgroundThemeColor,
        logoMonoUrl: hotel?.logoMonoUrl,
        // notificationListToMail: hotel?.notificationListToMail,
        displayRoomPictureDisclosure: hotel?.displayRoomPictureDisclosure,
        displayRoomCategoryInDetails: hotel?.displayRoomCategoryInDetails,
      },
      ...(hotel?.PropositionConfig ? {
        propositionConfig: {
          templateId: hotel?.PropositionConfig?.templateId,
          demoMode: hotel?.PropositionConfig?.demoMode,
          dest: hotel?.PropositionConfig?.dest
        }
      } : {}),
      ...(hotel?.ConfirmationConfig ? {
        confirmationConfig: {
          dest: hotel?.ConfirmationConfig?.dest,
          channel: hotel?.ConfirmationConfig?.channel,
          type: hotel?.ConfirmationConfig?.type,
          dataTypeActive: hotel?.ConfirmationConfig?.dataTypeActive,
          demoMode: hotel?.ConfirmationConfig?.demoMode,
          templateId: hotel?.ConfirmationConfig?.templateId,
        }
      } : {}),
      configRoom: hotel?.Room?.map(room => {

        const { hotelId, RoomType, ...rest } = room

        return {
          ...rest,
        }
      }),
      configOptions: {
        Option: hotel?.Option?.map((el: any) => {
          const { __typename, File, ...rest } = el
          return {
            ...rest,
          }
        })
      },
      configPriceRate: {
        PriceRateType: hotel.PriceRateType?.map((el) => {
          const { __typename, ...rest } = el
          return {
            // hotelId: props?.id,
            ...rest
          }
        })
      },
      ...(hotel.PriceRateCancelCondition?.length > 0 && {
        configCancelConditions: {
          PriceRateCancelCondition: hotel.PriceRateCancelCondition?.map((el: any) => {
            const { __typename, hotelId, ...cancelCondition } = el
            return {
              ...cancelCondition,
              conditionList: cancelCondition?.conditionList.map((cd: any) => {
                const { __typename, ...rest } = cd;
                return rest
              })
            }
          }),
        }
      }),
      ...(hotel?.RoomType?.length > 0 && {
        configRoomType: {
          RoomType: hotel.RoomType?.map((el: any) => {
            const { __typename, ...rest } = el
            return rest
          }),
        }
      } || {}),
      ...(hotel?.BillingConfig && {
        configBilling: {
          type: hotel?.BillingConfig?.type,
          templateId: hotel?.BillingConfig?.templateId,
          stripeConfig: hotel?.BillingConfig?.StripeConfig,
          terminalList: hotel?.BillingConfig?.Terminal?.map(terminal => {
            const { status, StripeOptions, ...terminalStripped } = terminal
            const { id, ...stripeOptions } = StripeOptions
            return {
              ...terminalStripped,
              stripeOptions,
            }
          })
          // options: hotel?.BillingConfig?.options,
        }
      } || {}),
      configStay: {
        ...(hotel?.PostStayConfig && {
          postStayConfig: {
            alertThreshold: hotel.PostStayConfig?.alertThreshold,
            sendHour: hotel.PostStayConfig?.sendHour,
            dest: hotel?.PostStayConfig?.dest,
            channel: hotel?.PostStayConfig?.channel,
            templateId: hotel?.PostStayConfig?.templateId,
            postStayActive: hotel?.PostStayConfig?.postStayActive,
            demoMode: hotel?.PostStayConfig?.demoMode,
            nbDaysAfterDeparture: hotel?.PostStayConfig?.nbDaysAfterDeparture,
            PostStayGroup: hotel?.PostStayConfig?.PostStayGroup?.map((postStayGroup: PostStayGroup) => {
              const { __typename, ...psInput } = postStayGroup
              return psInput

            })
          }
        } || {}),
      },
      configService: {
        ServiceList: hotel.ServiceList?.map((el: any) => {
          const { __typename, ...rest } = el
          return {
            ...rest
          }
        })
      },
      configSeasonality: hotel.Seasonality?.map(season => {
        const { __typename, ...rest } = season
        return {
          ...rest,
        }
      }),
      ...(hotel?.PhoneConfig ? {
        phoneConfig: {
          phoneConfigId: hotel.PhoneConfig?.phoneConfigId,
          clic2CallSequenceId: hotel.PhoneConfig?.clic2CallSequenceId,
          token: hotel.PhoneConfig?.token,
        }
      } : {}),
      ...(hotel?.NetworkConfig ? {
        networkConfig: {
          hostingConfigId: hotel.NetworkConfig?.hostingConfigId,
          token: hotel.NetworkConfig?.token,
        }
      } : {}),
      ...(hotel?.GoogleConfig ? {
        googleConfig: {
          placeId: hotel.GoogleConfig?.placeId,
        }
      } : {}),
      ...(hotel.TripAdvisorConfig ? {
        tripAdvisorConfig: {
          reviewUrl: hotel.TripAdvisorConfig?.reviewUrl
        }
      } : {}),
      ...(hotel.PmsConfig ? {
        pmsConfig: hotel.PmsConfig,
      } : {}),
      tokenList: hotel?.HotelToken?.map((ht) => ({ id: ht?.id, label: ht?.label, expiration: ht?.expiration, status: ht?.status })),
      videoConfig: {
        ...hotel?.VideoConfig,
        CameraList: hotel?.VideoConfig?.CameraList
      },
    }

  }


  const onChange = (hotel: HotelInput) => {
    updateFormUpdated(true)
    updateHotel(hotel)
  }
  const resetForm = () => {
    updateErrors(null)
    updateFormUpdated(false)
    updateHotel(hotelGetInput(data?.hotel))
  }

  return <div className="hotel-edit-container">
    <MutationWrapper mutation={hotelUpdate} onDone={(response, error) => {
      if (response) {
        updateHotel(hotelGetInput(response.data?.hotel))
        updateFormUpdated(false)
        updateErrors(null)
      }
      if (error) {
        updateErrors(formatErrorResponseForJoi(error))
        console.log("ERROR", errors)
      }
    }}>
      {(mutate: any, loading: boolean) => {

        const isAdmin = user?.roles?.includes(UserRoleEnum.ROLE_ADMIN)
        const isConfig = isAdmin || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG)
        const isConfigGeneral = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_GENERAL)
        const isConfigSeasonality = isConfig
        const isPhoneConfig = isConfigGeneral
        const isPmsConfig = isConfigGeneral
        const isSocialConfig = isConfigGeneral
        const isNetworkConfig = isConfigGeneral
        const isVideoConfig = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_VIDEO_CONFIG)
        const isConfigConfirmation = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_CONFIRMATION)
        const isConfigProposition = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_PROPOSITION)
        const isConfigServices = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_SERVICE)
        const isConfigOptions = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_OPTIONS)
        const isConfigPriceRate = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_RATE_CODE)
        const isConfigCancelCondition = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_CANCEL_CONDITIONS)
        const isConfigRoomType = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_ROOM_TYPE)
        const isConfigBilling = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_BILLING)
        const isConfigPostStay = isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_POST_STAY)
        // const { name, displayRoomPictureDisclosure, displayRoomCategoryInDetails, themeColor, email, address, cgvUrlEnFileId, cgvUrlFrFileId, stayTax, phoneNumber, checkInHour, checkOutHour, breakfastStartHour, breakfastEndHour, demoMode, confirmationDest, confirmationSender, confirmationChannel, confirmationType, confirmationTemplateFr, confirmationTemplateEn, propositionTemplateFr, propositionTemplateEn, initials, RoomType, PriceRateType, Option, PriceRateCancelCondition, BillingConfig, PostStayConfig, ServiceList, logoUrl, backgroundUrl, notificationListToMail, confirmationDataTypeActive, PhoneConfig, NetworkConfig, PmsConfig, TripAdvisorConfig, GoogleConfig, ConfirmationConfig } = hotel || {}

        // const { __typename, id, ...billingConfig } = BillingConfig || {}

        const saveForm = () => {

          // const { __typename, id, hotelId, PostStayGroup, ...postStayConfig } = PostStayConfig || {}

          mutate({
            refetchQueries: [{
              query: priceRateCancelConditionListGql,
              variables: {
                hotelId: props?.id,
              }
            }],
            variables: {
              id: props?.id,
              input: {
                ...(isConfigGeneral ? {
                  configGeneral: hotel.configGeneral
                } : {}),
                ...(isConfigConfirmation ? {
                  confirmationConfig: hotel.confirmationConfig,
                } : {}),
                ...(isConfigProposition ? {
                  propositionConfig: hotel.propositionConfig,
                } : {}),
                ...(isConfigOptions ? {
                  configOptions: hotel?.configOptions,
                } : {}),
                ...(isConfigPriceRate ? {
                  configPriceRate: hotel?.configPriceRate
                } : {}),
                ...(isConfigCancelCondition ? {
                  configCancelConditions: hotel.configCancelConditions
                } : {}),
                ...(isConfigRoomType ? {
                  configRoomType: hotel.configRoomType
                } : {}),
                ...(isConfigPostStay ? {
                  configStay: hotel.configStay,
                } : {}),
                ...(isConfigServices ? {
                  configService: hotel.configService,
                } : {}),
                ...(isConfigBilling ? {
                  configBilling: hotel.configBilling,
                } : {}),
                ...(isConfigSeasonality ? {
                  configSeasonality: hotel.configSeasonality,
                } : {}),
                ...(isPhoneConfig ? {
                  phoneConfig: hotel.phoneConfig,
                } : {}),
                ...(isNetworkConfig ? {
                  networkConfig: hotel.networkConfig,
                } : {}),
                ...(isSocialConfig ? {
                  tripAdvisorConfig: hotel.tripAdvisorConfig,
                  googleConfig: hotel.googleConfig,
                } : {}),
                ...(isPmsConfig ? {
                  pmsConfig: hotel.pmsConfig,
                } : {}),
                ...(isVideoConfig ? {
                  videoConfig: hotel?.videoConfig,
                } : {}),
                tokenList: hotel?.tokenList,
                configRoom: hotel.configRoom,
              },
            },
          })

        }

        return <div>

          <hotelContext.Provider value={data?.hotel}>

            <HotelForm hotel={hotel} errors={errors} onChange={(hotel: any) => {
              onChange(hotel)
            }}
            />

          </hotelContext.Provider>
          {formUpdated && <div className="card" style={{ position: "sticky", bottom: 10, margin: 10 }}>
            <div className="card-body">
              <div className="actions">
                <button className="btn btn-sm  btn-dark" disabled={loading} type="button" onClick={() => saveForm()}>
                  {loading && <span className="icon-loading ml-1"></span>}
                  Enregister
                </button>
                <button className="btn btn-sm btn-danger" type="button" onClick={() => resetForm()}>Annuler</button>
              </div>
            </div>
          </div>}
        </div>

      }}

    </MutationWrapper>

  </div>

}